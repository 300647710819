<template>
  <section class="option-container text-unselect">
    <div
      :class="[
        'content-box',
        {
          'is-web': !isMobile,
          'is-mobile': isMobile,
        },
      ]"
    >
      <!--      <div class="blue-bg"></div>-->
      <h2 class="content-title">NFTクリエーター</h2>
      <div class="btn-back" @click="goHome">
        <svg
          t="1684141995109"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2389"
          width="24"
          height="24"
        >
          <path
            d="M946.5 505L560.1 118.8l-25.9-25.9c-12.3-12.2-32.1-12.2-44.4 0L77.5 505c-12.3 12.3-18.9 28.6-18.8 46 0.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8 12.1-12.1 18.7-28.2 18.7-45.3 0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204z m217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z"
            p-id="2390"
            fill="#ffffff"
          ></path>
        </svg>
      </div>
      <section class="content-center">
        <div style="text-align: center">
          <p class="content-info-title">
            <span style="font-size: 18px">
              あなたのイメージを形にしたNFTを生成する為に、以下のキーワードから選択して下さい。</span
            ><br />
            <span style="color: red; font-size: 14px"
              >※一度選択すると変更できません。</span
            >
          </p>
          <section class="nft-style-options">
            <v-list dark class="v-list">
              <v-list-item-group
                v-model="model"
                multiple
                color="rgba(25, 118, 210, 1)"
              >
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  :class="[
                    {
                      'ft-size-12': item.id === 1 || item.id === 2,
                    },
                    {
                      'ft-size-15': item.id === 7,
                    },
                    'list-item',
                    'item-btn',
                  ]"
                  active-class="item-btn-after"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-text-field
              dark
              label="お好きなキーワードをお書きください"
              required
              v-model="text"
              class="input-text"
              clearable
              rounded
              solo
            ></v-text-field>

            <!--            <v-img-->
            <!--              src="/static/image/random_pig.jpg"-->
            <!--              width="250px"-->
            <!--              height="250px"-->
            <!--              style="margin: 0 auto"-->
            <!--            ></v-img>-->
            <section class="btn-operation">
              <v-btn
                x-large
                @click="btnToContinue"
                :loading="loading"
                rounded
                class="btn-continue"
              >
                Continue
              </v-btn>
            </section>
          </section>
        </div>
      </section>
    </div>
    <v-dialog v-model="queueVisible" persistent width="320">
      <v-card class="queue-dialog">
        <v-card-text>
          <span class="queue-text"
            >{{ $t("message.queue")
            }}<span class="queue-index">{{ queue_index }}</span>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn block text @click="handleLeave" class="queue-dialog-btn"
            >{{ $t("message.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { browser } from "@/utils/utils.js";
import { select_nft_type } from "@/api/user";

export default {
  name: "Option",
  data() {
    return {
      text: "",
      items: [
        {
          id: 1,
          name: "スチームパンク",
          value: "steampunk,punk costume,, ,",
        },
        {
          id: 2,
          name: "アイアンマン風",
          value:
            "<lora:warframe_v1-000012:0.5>,(masterpiece:1.2),perfect lighting, extremely detailed CG,glowing eyes,backlighting,(clear pig face:1.3),, ,",
        },
        {
          id: 3,
          name: "未来シティ",
          value: "cyber punk style,mech concept,, ,",
        },
        {
          id: 4,
          name: "ゴージャス",
          value: `<lora:IvoryGoldAIv2:0.5>,Gorgeous Helmet,Gorgeous Battle Armor,Gorgeous Decoration, `,
        },
        {
          id: 5,
          name: "宇宙飛行士",
          value:
            "cyber punk style,mech concept,wearing space suit, helmet,rebreather,,",
        },
        {
          id: 6,
          name: "ポリス",
          value: "(policeman:1.2),Police Uniform,Police car background ,",
        },
        {
          id: 7,
          name: "幻想シティ",
          value:
            "(Neon Background),neon lights,wide shot,cinematic lighting,a scene of city,the Kowloon Walled City ,",
        },
        {
          id: 8,
          name: "宇宙",
          value: "rebreather,Meteor, Galaxy, Spaceship,space background, ,",
        },
        {
          id: 9,
          name: "大自然",
          value: " Natural background, warm sunshine, grass,,",
        },
        {
          id: 10,
          name: "マジック",
          value:
            " professional lighting,island sanctuary,ancient fallen kingdom,(reflections in water),drowned city,(depth of field),",
        },
      ],
      model: [],
      isMobile: false,
      queueVisible: false,
      isSelect: {
        first: 0,
        second: 0,
      },
      loading: false,
      lastPrompt: "",
      queue_index: -1,
      timer: null,
      isQueueVisible: false,
    };
  },
  computed: {
    isDisabled() {
      return this.model.length === 0;
    },
  },
  watch: {
    model: function (val) {
      console.log("model", val);
      this.lastPrompt = "";
      if (val.length > 0) {
        for (let i = 0; i < val.length; i++) {
          var _i = val[i] + 1;
          for (let j = 0; j < this.items.length; j++) {
            if (_i === this.items[j].id) {
              console.log("j", this.items[j].id);
              this.lastPrompt += this.items[j].value;
            }
          }
        }
      }
      console.log(this.lastPrompt);
    },
  },
  methods: {
    goHome() {
      this.$router.push({
        path: "/",
      });
    },
    changeSelect(type, id) {
      if (this.loading) return;
      this.isSelect[type] = id;
    },
    btnToContinue() {
      console.log(this.lastPrompt);
      if (this.loading) return;
      this.loading = true;
      select_nft_type({
        prompt: this.lastPrompt,
        text: this.text,
      })
        .then((res) => {
          console.log("data", res.data);
          if (res.message === "ok") {
            this.closePopup();
            this.optionReset();
            this.$store.commit("user/setSelectImg", res.data);
            this.$router.push({
              path: "/choose_nft",
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log("err select_nft:", err);
          if (err.message === "wait") {
            this.queue_index = parseInt((err?.data ?? 0) + 1);
            this.lastPrompt = prompt;
            this.popupQueueWindow();
          } else {
            this.loading = !this.loading;
            this.optionReset();
            this.$store.commit("snackbar/setSnackbar", {
              show: true,
              text: err.message || this.$t("network.refreshAgain"),
            });
          }
          this.loading = false;
        });
    },
    optionReset() {
      this.lastPrompt = "";
      this.model = [];
      this.text = "";
    },
    popupQueueWindow() {
      if (this.isQueueVisible) return;
      if (!this.queueVisible) {
        this.queueVisible = true;
      }
      try {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
      } catch (e) {
        console.log(e);
      }
      this.timer = setInterval(() => {
        this.btnToContinue(this.lastPrompt);
      }, 5000);
    },
    closePopup() {
      try {
        this.timer && clearInterval(this.timer);
        this.timer = null;
      } catch (e) {
        console.log(e);
      }
      this.queueVisible = false;
      this.isQueueVisible = false;
      this.queue_index = -1;
    },
    handleLeave() {
      try {
        this.timer && clearInterval(this.timer);
        this.timer = null;
      } catch (e) {
        console.log(e);
      }
      this.closePopup();
      // api request
    },
  },
  created() {
    this.$store.commit("user/setSelectImg", []);
    this.isMobile = browser?.versions?.mobile ?? false;
    this.loading = false;
    this.queueVisible = false;
    this.lastPrompt = "";
    this.isSelect = {
      first: 0,
      second: 0,
    };
    this.$store.commit("system/isShowNavDrawer", false);
    this.closePopup();
  },
};
</script>

<style scoped lang="scss">
.option-container {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  background-color: #f8fafc;
}

.is-web {
  width: 640px;
  height: 100vh;
  margin: 0 auto;
}

.is-mobile {
  width: 100%;
  height: 100vh;
}

.btn-back {
  position: absolute;
  top: 16px;
  left: 6%;
  z-index: 2;
}

.content-box {
  position: relative;
  background: url(/static/image/nft_page_bg.png) no-repeat center center;
  background-size: cover;
  border-radius: 28px;
}

.content-title {
  width: 100%;
  text-align: center;
  height: 60px;
  line-height: 60px;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 24px;
  color: #ffffff;
}

.content-center {
  width: 90%;
  height: calc(100vh - 60px);
  overflow: hidden;
  position: absolute;
  background-color: rgba(26, 26, 26, 0.6);

  left: 5%;
  top: 60px;
  border-radius: 26px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.input-text {
  box-sizing: border-box;
  //width: 279px;
  height: 40px;
  background: #091522;
  //border: 1px solid #ffffff;
  border-radius: 44px;
  flex: none;
  order: 1;
  flex-grow: 0;
  color: #ffffff;
  //border-radius: 30px;
  margin: 40px auto 0;
  width: 90%;
  ::v-deep .v-label {
    font-size: 22px;
  }
  ::v-deep .v-input__slot {
    border: 1px solid #7ccdf8;
  }
}
.content-info-title {
  text-align: start;
  margin: 30px auto 20px;
  font-size: 20px;
  width: 88%;
  background-color: rgba(26, 26, 26, 0);
  padding: 10px;
  border-radius: 10px;
  color: white;
}

.blue-bg {
  width: 100%;
  height: 30vh;
  background-color: rgb(83, 157, 215);
  position: absolute;
  left: 0;
  top: 0;
}

.title {
  width: 100%;
  font-weight: 700;
  font-size: 1.4rem;
  margin-top: 20px;
  max-height: 36px;
  margin-bottom: 0.6rem;
  text-align: center;
  box-sizing: border-box;
}

.form-title {
  text-align: left;
  margin: unset;
  padding-left: 1.6rem;
}

.nft-style-options {
  width: 100%;
  height: 64vh;
  overflow-y: auto;
  box-sizing: border-box;
  //background-color: rgba(26,26,26,0.6);
  //overflow:hidden;
}
.v-list {
  background-color: rgba(26, 26, 26, 0);
}

.one-line {
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  margin-top: 20px;
}

.form-title {
  text-align: left;
  margin: unset;
  padding-left: 1.6rem;
}

.list-item {
  //display: inline-block;
  //min-width: 100px;
  text-align: center;
  //border: 1px solid rgba(25, 118, 210, 1);
  //border-radius: 30px;
  margin-top: 14px;
  margin-left: 10px;
  margin-right: 10px;

  display: inline-grid;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  width: 140px;
  height: 44px;
  color: white;
  /* dark/gradient */

  background: linear-gradient(
    223.81deg,
    #00e5e5 8.72%,
    #72a5f2 50.87%,
    #e961ff 91.3%
  );
  border-radius: 44px;

  /* Inside auto layout */

  flex: none;
  order: 3;
  flex-grow: 0;
}

::v-deep .v-list-item__title {
  font-size: 18px;
}

.v-list-item--link:before {
  border-radius: 30px;
  color: black;
}

.item-btn-after {
  background: linear-gradient(
    223.81deg,
    #328484 8.72%,
    #1e457d 50.87%,
    #4b144f 91.3%
  );
}

.btn-operation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 3rem;
  z-index: 2;
}

.btn-submit {
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.2rem;
}

.btn-continue {
  width: 80%;
  background: linear-gradient(
    223.81deg,
    #00e5e5 8.72%,
    #72a5f2 50.87%,
    #e961ff 91.3%
  );
  color: white;
  //box-shadow: 0 10px 20px rgba(255,96,96,.4)
}

.btn-continue-disabled {
  width: 80%;
  background: #cccccc;
}

.btn-menu {
  width: 5rem;
  background: linear-gradient(
    -42.55deg,
    #fb00c4 -20.67%,
    #ca0eff -3.42%,
    #febf1c 66.47%
  );
}

.queue-dialog {
  background-color: rgba(26, 26, 26, 0.8);
  border-radius: 26px;
}

.queue-text {
  padding-top: 10px;
  display: inline-block;
  font-size: 18px;
  color: white;
}

.queue-index {
  font-size: 19px;
  color: #f0804b;
  font-weight: bold;
  margin: 0 4px;
}

.item-btn {
}
.queue-dialog-btn {
  background: linear-gradient(
    223.81deg,
    #00e5e5 8.72%,
    #72a5f2 50.87%,
    #e961ff 91.3%
  );
  border-radius: 44px;
  color: white;
}

.ft-size-15 ::v-deep .v-list-item__title {
  font-size: 15px;
}
.ft-size-12 ::v-deep .v-list-item__title {
  font-size: 12px;
}
</style>
