var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"option-container text-unselect"},[_c('div',{class:[
      'content-box',
      {
        'is-web': !_vm.isMobile,
        'is-mobile': _vm.isMobile,
      },
    ]},[_c('h2',{staticClass:"content-title"},[_vm._v("NFTクリエーター")]),_c('div',{staticClass:"btn-back",on:{"click":_vm.goHome}},[_c('svg',{staticClass:"icon",attrs:{"t":"1684141995109","viewBox":"0 0 1024 1024","version":"1.1","xmlns":"http://www.w3.org/2000/svg","p-id":"2389","width":"24","height":"24"}},[_c('path',{attrs:{"d":"M946.5 505L560.1 118.8l-25.9-25.9c-12.3-12.2-32.1-12.2-44.4 0L77.5 505c-12.3 12.3-18.9 28.6-18.8 46 0.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8 12.1-12.1 18.7-28.2 18.7-45.3 0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204z m217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z","p-id":"2390","fill":"#ffffff"}})])]),_c('section',{staticClass:"content-center"},[_c('div',{staticStyle:{"text-align":"center"}},[_vm._m(0),_c('section',{staticClass:"nft-style-options"},[_c('v-list',{staticClass:"v-list",attrs:{"dark":""}},[_c('v-list-item-group',{attrs:{"multiple":"","color":"rgba(25, 118, 210, 1)"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,class:[
                  {
                    'ft-size-12': item.id === 1 || item.id === 2,
                  },
                  {
                    'ft-size-15': item.id === 7,
                  },
                  'list-item',
                  'item-btn',
                ],attrs:{"active-class":"item-btn-after"}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)}),1)],1),_c('v-text-field',{staticClass:"input-text",attrs:{"dark":"","label":"お好きなキーワードをお書きください","required":"","clearable":"","rounded":"","solo":""},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),_c('section',{staticClass:"btn-operation"},[_c('v-btn',{staticClass:"btn-continue",attrs:{"x-large":"","loading":_vm.loading,"rounded":""},on:{"click":_vm.btnToContinue}},[_vm._v(" Continue ")])],1)],1)])])]),_c('v-dialog',{attrs:{"persistent":"","width":"320"},model:{value:(_vm.queueVisible),callback:function ($$v) {_vm.queueVisible=$$v},expression:"queueVisible"}},[_c('v-card',{staticClass:"queue-dialog"},[_c('v-card-text',[_c('span',{staticClass:"queue-text"},[_vm._v(_vm._s(_vm.$t("message.queue"))),_c('span',{staticClass:"queue-index"},[_vm._v(_vm._s(_vm.queue_index))])])]),_c('v-card-actions',[_c('v-btn',{staticClass:"queue-dialog-btn",attrs:{"block":"","text":""},on:{"click":_vm.handleLeave}},[_vm._v(_vm._s(_vm.$t("message.close"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"content-info-title"},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(" あなたのイメージを形にしたNFTを生成する為に、以下のキーワードから選択して下さい。")]),_c('br'),_c('span',{staticStyle:{"color":"red","font-size":"14px"}},[_vm._v("※一度選択すると変更できません。")])])
}]

export { render, staticRenderFns }